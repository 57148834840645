/* eslint-disable */

<template>
  <div id="Lgx279924608939851776">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row">
        <div class="vx-col w-1/3">
          <vx-card class="mb-3">
            <h3 class="font-bold mb-3">Selection</h3>
            <div class="">
              <vue-draggable-container id="wordBank" class="wordBank">
                <h5 class="dragBlock my-1 cursor-pointer" style="background: #ffd86b">Yellow</h5>
                <h5 class="dragBlock my-1 cursor-pointer" style="background: #3bdb87">Green</h5>
                <h5 class="dragBlock my-1 cursor-pointer" style="background: #259fff">Blue</h5>
                <h5 class="dragBlock my-1 cursor-pointer" style="background: #856c65">Brown</h5>
                <h5
                  class="dragBlock my-1 cursor-pointer"
                  style="
                    background-image: linear-gradient(
                      111deg,
                      #ffd86b 25%,
                      #ffffff 25%,
                      #ffffff 50%,
                      #ffd86b 50%,
                      #ffd86b 75%,
                      #ffffff 75%,
                      #ffffff 100%
                    );
                    background-size: 42.85px 111.62px;
                  "
                >
                  Yellow Striped
                </h5>
                <h5
                  class="dragBlock my-1 cursor-pointer"
                  style="
                    background-image: linear-gradient(
                      111deg,
                      #3bdb87 25%,
                      #ffffff 25%,
                      #ffffff 50%,
                      #3bdb87 50%,
                      #3bdb87 75%,
                      #ffffff 75%,
                      #ffffff 100%
                    );
                    background-size: 42.85px 111.62px;
                  "
                >
                  Green Striped
                </h5>
                <h5
                  class="dragBlock my-1 cursor-pointer"
                  style="
                    background-image: linear-gradient(
                      111deg,
                      #259fff 25%,
                      #ffffff 25%,
                      #ffffff 50%,
                      #259fff 50%,
                      #259fff 75%,
                      #ffffff 75%,
                      #ffffff 100%
                    );
                    background-size: 42.85px 111.62px;
                  "
                >
                  Blue Striped
                </h5>
                <h5
                  class="dragBlock my-1 cursor-pointer"
                  style="
                    background-image: linear-gradient(
                      111deg,
                      #856c65 25%,
                      #ffffff 25%,
                      #ffffff 50%,
                      #856c65 50%,
                      #856c65 75%,
                      #ffffff 75%,
                      #ffffff 100%
                    );
                    background-size: 42.85px 111.62px;
                  "
                >
                  Brown Striped
                </h5>
              </vue-draggable-container>
            </div>
          </vx-card>

          <vx-card class="" title="Page Switcher">
            <vs-button
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-right-circle"
              style="width: 100%; z-index: 1"
              class="font-bold flex"
              size="large"
              @click="changePage('next')"
              v-if="currentPage === 0"
              >Next Page
            </vs-button>
            <vs-button
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-left-circle"
              style="width: 100%"
              class="font-bold"
              size="large"
              @click="changePage('previous')"
              v-else
              >Previous Page
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col w-2/3">
          <vx-card class="mb-base" :style="{ display: currentPage === 0 ? 'block' : 'none!important' }">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <h3 class="mb-4">Select the tool that was used to create the cable test results.</h3>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="toner_probe"
                    >Toner Probe</vs-radio
                  >
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="cable_certifier"
                    >Cable Certifier</vs-radio
                  >
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="punch_down_tool"
                    >Punch Down Tool</vs-radio
                  >
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="crimper">Crimper</vs-radio>
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="otdr">OTDR</vs-radio>
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="protocol_sniffer"
                    >Protocol Sniffer</vs-radio
                  >
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="toolSelected" vs-value="multimeter">Multimeter</vs-radio>
                </div>
                <h3 v-if="this.toolCorrect === false" class="mt-2 text-danger font-light">This answer is incorrect</h3>
                <h3 v-if="this.toolCorrect === true" class="mt-2 text-success font-light">This answer is correct</h3>
              </div>
              <div class="vx-col w-1/2">
                <div class="p-3 border-solid border-white">
                  <h3 class="mb-3">Cable Test Result</h3>
                  <div class="grid grid-cols-3 gap-3 border-solid border-white p-2 mb-2">
                    <h1 class="text-center">1.2</h1>
                    <h1 class="text-center">open</h1>
                    <h1 class="text-center">7ft</h1>
                  </div>
                  <div class="grid grid-cols-3 gap-3 border-solid border-white p-2 mb-2">
                    <h1 class="text-center">3.6</h1>
                    <h1 class="text-center">short</h1>
                    <h1 class="text-center">7ft</h1>
                  </div>
                  <div class="grid grid-cols-3 gap-3 border-solid border-white p-2 mb-2">
                    <h1 class="text-center">4.5</h1>
                    <h1 class="text-center">open</h1>
                    <h1 class="text-center">7ft</h1>
                  </div>
                  <div class="grid grid-cols-3 gap-3 border-solid border-white p-2">
                    <h1 class="text-center">7.8</h1>
                    <h1 class="text-center">open</h1>
                    <h1 class="text-center">7ft</h1>
                  </div>
                </div>
              </div>
              <div class="vx-col mt-6">
                <h3 class="mb-4">Select the tool that was used to create the cable test results.</h3>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="crimpedSelected" vs-value="correct"
                    >Correctly Crimped Cable</vs-radio
                  >
                </div>
                <div class="mb-2">
                  <vs-radio style="font-size: 1.6rem" class="font-light" v-model="crimpedSelected" vs-value="incorrect"
                    >Incorrectly Crimped Cable</vs-radio
                  >
                </div>
                <h3 v-if="this.crimpedCorrect === false" class="mt-2 text-danger font-light">This answer is incorrect</h3>
                <h3 v-if="this.crimpedCorrect === true" class="mt-2 text-success font-light">This answer is correct</h3>
              </div>
            </div>
          </vx-card>
          <vx-card class="mb-base" :style="{ display: currentPage === 1 ? 'block' : 'none!important' }">
            <div class="vx-row">
              <div class="vx-col w-full mb-6">
                <h3 class="">
                  You have determined that the original crimp is faulty. Re-crimp the cable connector using the T568B cabling standard.
                  Choose the correct cable for each pin in the connector by clicking on the pin and selecting a cable color.
                </h3>
              </div>
              <div class="vx-col w-1/2 flex items-center justify-center">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/cable.png"
                  style="max-width: 100%"
                  alt=""
                  class="network-first-cable-image"
                  draggable="false"
                />
              </div>
              <div class="vx-col w-1/2 flex items-center justify-center">
                <div class="w-full border-solid border-white rounded-lg">
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>1</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input0">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>2</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input1">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>3</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input2">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>4</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input3">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>5</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input4">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>6</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input5">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>7</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input6">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                  <div class="vx-row pt-1 pb-1 pl-4 pr-4">
                    <div class="vx-col w-1/12 flex items-center justify-center">
                      <h1>8</h1>
                    </div>
                    <div class="vx-col w-8/12">
                      <vue-draggable-container class="answerCableDrop dropzone dropzoneStandard answer-bg" id="input7">
                      </vue-draggable-container>
                    </div>
                    <div class="vx-col p-0 w-3/12 flex items-center justify-center">
                      <div class="cablePinWire w-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      currentPage: 0,
      crimpedSelected: null,
      crimpedCorrect: null,
      toolSelected: null,
      toolCorrect: null,

      //Sim Requirements
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-cable';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (this.crimpedSelected === 'incorrect') {
        totalScore++;
        this.crimpedCorrect = true;
      } else {
        this.crimpedCorrect = false;
      }

      if (this.toolSelected === 'cable_certifier') {
        totalScore++;
        this.toolCorrect = true;
      } else {
        this.toolCorrect = false;
      }

      if (document.getElementById('input0').textContent === 'Yellow Striped') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Yellow') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Green Striped') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Blue') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'Blue Striped') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === 'Green') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input6').textContent === 'Brown Striped') {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input7').textContent === 'Brown') {
        document.getElementById('input7').style.borderColor = '#28C76F';
        document.getElementById('input7').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
  },
  mounted() {},
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#Lgx279924608939851776 {
  .dragBlock {
    color: #000000;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 8px;
    width: 100%;
  }
  .answerCableDrop {
    height: 100%;
    min-height: 49px;
    width: 100%;

    .dragBlock {
      width: 94.5%;
    }

    .draggable-source--is-dragging {
      width: 94.5% !important;
    }

    .currently-dragging-cable {
      min-width: 13.5%;
      width: auto;
    }
  }
  .draggable-source--is-dragging {
    width: 100% !important;
  }
  .currently-dragging-cable {
    min-width: 13.5%;
    width: auto;
  }
  .cablePinWire {
    height: 20px;
    border-style: solid;
    border-width: 2px 20px;
    border-color: #fff;
    background-color: #dfb429;
  }
}
</style>
